<template>
  <div class="hotCircle">
    <main class="hotCircle_list_box" :style="{minHeight: height + 'px'}">
      <template v-if="hot_list.length > 0">
        <div class="hot_Item" v-for="(item, index) in hot_list" :key="index">
          <CircleItem
            :item="item"
            :user="user"
            @quitCircle="handleQuitCircle"
            @joinCircle="handleJoinCircle"
            :isShowIntro="isShowIntro"
            :isComeManage="isComeManage"
            @myGlCircle="myGlCircle"
          ></CircleItem>
        </div>
      </template>
      <!-- todo  推荐的圈子-->
      <template v-if="isRecommend">
        <div class="hot_Item" v-for="(item, index) in InterestCircle" :key="index">
          <CircleItem
            :item="item"
            :user="user"
            @quitCircle="handleQuitCircle"
            @joinCircle="handleJoinCircle"
            :isShowIntro="isShowIntro"
            :isComeManage="isComeManage"
            @myGlCircle="myGlCircle"
          ></CircleItem>
        </div>
      </template>
      <!-- <van-skeleton
        v-if="!isLoaded && hot_list.length === 0"
        title
        :row="10" /> -->
      <div v-if="isShowNoData">
        <van-empty
          class="custom-image"
          v-if="hot_list.length === 0 && !isRecommend"
          :image="Empty"
          :description="noDataDescription">
        </van-empty>
      </div>
    </main>
  </div>
</template>

<script>
import Empty from '/public/images/circle/empty.png'
import {mapGetters} from "vuex";
import CircleItem from '@/components/circle/circleItem'

export default {
  name: 'hotCircle',
  components: {CircleItem},
  props: {
    // 是否显示空页面
    isShowNoData: {
      type: Boolean,
      default: false
    },
    noDataDescription: {
      type: String,
      default: '暂无数据'
    },
    isCreateTopic: {
      type: Boolean,
      default: false
    },
    isRecommend: {
      type: Boolean,
      default: false
    },
    // 是否显示简介
    isShowIntro: {
      type: Boolean,
      default: false
    },
    // 是否来自我的管理
    isComeManage: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 455,
    },
    nodataHi: {
      type: Number,
      default: 144
    },
    ContSet: {
      type: Object,
      default: () => {
      }
    },
    hot_list: {
      type: Array,
      default: () => []
    },
    InterestCircle: {
      type: Array,
      default: () => []
    },
    noDataInfo: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      Empty,
      loading: false,
      finished: false,
      applyQuitItem: {}, // 当前要加入或退出的圈子item
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    handleJoinCircle(item) {
      this.$emit('joinCircle', item)
    },
    handleQuitCircle() {
      this.$emit('quitCircle')
    },
    goCreateCircle() {
      this.$router.push('/app/circle/myCreateCircle')
    },
    loadMore() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // 加载状态结束
      this.loading = false;
      this.finished = false;
    },

    // 我管理的圈子退出
    myGlCircle(item) {
      this.$emit('myGlCircle', item)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .custom-image .van-empty__image {
    width: 212px;
    height: 114px;
  }

  .van-dialog__content {
    margin: 20px;

    .van-dialog__message.van-dialog__message--has-title {
      white-space: pre-wrap !important;
      font-size: 100px;
    }
  }
}

.hotCircle {
  background: #fff;
  z-index: 9;

  .default-quit {
    text-align: center;
    font-family: 'PingFang SC';
    font-size: 14px;
    line-height: 23px;
    color: #222222;
  }

  .hotCircle_list_box {
    margin-top: 20px;
    overflow: scroll;

    .add-circle {
      font-size: 14px;
      color: $mainColor;
    }
  }
}
</style>
